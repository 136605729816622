import styled from 'styled-components';

const HowItWorksStepTitle = styled.h5`
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.6rem;
  margin-bottom: 0.875rem;
  margin-top: 0;
`;

export { HowItWorksStepTitle };