import styled from 'styled-components';
import { SCREEN_SIZE } from '../../../../../style';
import { Centralized } from '../../../../shared/layout';

const HowItWorksStep = styled(Centralized)`
  text-align: center;
  ${SCREEN_SIZE.LG} {
    text-align: left;
    display: flex;
    max-width: 43.75rem;
    margin: 70px auto;
    align-items: center;
    ${({ extraMarginTop }) => extraMarginTop && 'margin-top: 112px;'}
  }
  ${({ reverse }) => reverse && 'flex-direction: row-reverse;'}
`;

export { HowItWorksStep };