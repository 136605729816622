import React from 'react';
import styled from 'styled-components';
import {
  HowItWorksStep, HowItWorksStepImageSection, HowItWorksStepContentSection,
  HowItWorksStepTitle, HowItWorksStepDescription,
} from './HowItWorksStep';
import HowItWorksStep1ImageSource from './images/how-it-works-step-1.png';
import HowItWorksStep2ImageSource from './images/how-it-works-step-2.png';
import HowItWorksStep3ImageSource from './images/how-it-works-step-3.png';
import HowItWorksStep4ImageSource from './images/how-it-works-step-4.png';
import HowItWorksStep5ImageSource from './images/how-it-works-step-5.png';
import HowItWorksStepLineImageSource from './images/how-it-works-step-line.png';
import HowItWorksStepRocketImageSource from './images/rocket.svg';
import HowItWorksStepBigRocketImageSource from './images/big-rocket.svg';
import { SCREEN_SIZE, STYLES } from '../../../../style';

const StyledHowItWorksSteps = styled.div`
  padding-bottom: 3rem;
`;

const HowItWorksStepNo = styled.div`
  background-color: ${STYLES.COLOR_BITTER_SWEET_RED};
  border-radius: 5rem;
  display: flex;
  align-items: center;
  transform: translate(-50%, -23rem);
  left: 50%;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  color: ${STYLES.COLOR_WHITE};
  font-size: 1.25rem;
  position: absolute;
  ${SCREEN_SIZE.LG} {
    transform: translateY(-3rem);
    left: auto;
    width: 2.8rem;
    height: 2.8rem;
  }
`;

const HowItWorksStepRocketImage = styled.img.attrs({
  src: HowItWorksStepRocketImageSource,
})`
  display: none;
  ${SCREEN_SIZE.LG} {
    display: block;
    position: absolute;
    width: 62px;
    left: 50%;
    transform: translate(-38px,-135px);
  }
`;

const HowItWorksStepLineImage = styled.img.attrs({
  src: HowItWorksStepLineImageSource,
})`
  display: none;
  ${SCREEN_SIZE.LG} {
    display: block;
    position: absolute;
    z-index: -1;
    transform: translate(-50%, -69px);
    left: 50%;
    width: 627px;
  }
`;

const HowItWorksStepImage = styled.img`
  margin: 12rem 0 4rem;
  ${SCREEN_SIZE.LG} {
    margin: 0;
    ${({ right }) => (right ? 'transform: translateX(70px)' : 'transform: translateX(-66px)')}
  }
`;

const HowItWorksStepBigRocketImage = styled.img.attrs({
  src: HowItWorksStepBigRocketImageSource,
})`
  display: none;
  ${SCREEN_SIZE.LG} {
    display: block;
    position: absolute;
    right: 5%;
    top: 45rem;
  }
`;

const HowItWorksSteps = () => (
  <StyledHowItWorksSteps>
    <HowItWorksStepBigRocketImage />
    <HowItWorksStepRocketImage />
    <HowItWorksStepLineImage />
    <HowItWorksStep reverse>
      <HowItWorksStepImageSection>
        <HowItWorksStepImage right src={HowItWorksStep1ImageSource} />
      </HowItWorksStepImageSection>
      <HowItWorksStepContentSection>
        <HowItWorksStepNo>1</HowItWorksStepNo>
        <HowItWorksStepTitle>Places Order</HowItWorksStepTitle>
        <HowItWorksStepDescription>
          Your customer places an order online like they normally would.
          Nothing changes. You package up the order and prepare it for delivery.
        </HowItWorksStepDescription>
      </HowItWorksStepContentSection>
    </HowItWorksStep>
    <HowItWorksStep>
      <HowItWorksStepImageSection>
        <HowItWorksStepImage src={HowItWorksStep2ImageSource} />
      </HowItWorksStepImageSection>
      <HowItWorksStepContentSection extraMarginTop>
        <HowItWorksStepNo>2</HowItWorksStepNo>
        <HowItWorksStepTitle>Posts Job</HowItWorksStepTitle>
        <HowItWorksStepDescription>
          Instead of taking the parcel to the post office, you simply open up
          the Hand Carry app and submit the job.
        </HowItWorksStepDescription>
      </HowItWorksStepContentSection>
    </HowItWorksStep>
    <HowItWorksStep reverse extraMarginTop>
      <HowItWorksStepImageSection>
        <HowItWorksStepImage right src={HowItWorksStep3ImageSource} />
      </HowItWorksStepImageSection>
      <HowItWorksStepContentSection>
        <HowItWorksStepNo>3</HowItWorksStepNo>
        <HowItWorksStepTitle>Accepted</HowItWorksStepTitle>
        <HowItWorksStepDescription>
          Hand Carry matches your job with a suitable commuter. We match based
          on movements and the location of the commuters. A suitable commuter
          then accepts the job and comes to you.
        </HowItWorksStepDescription>
      </HowItWorksStepContentSection>
    </HowItWorksStep>
    <HowItWorksStep>
      <HowItWorksStepImageSection>
        <HowItWorksStepImage src={HowItWorksStep4ImageSource} />
      </HowItWorksStepImageSection>
      <HowItWorksStepContentSection extraMarginTop>
        <HowItWorksStepNo>4</HowItWorksStepNo>
        <HowItWorksStepTitle>Pick up</HowItWorksStepTitle>
        <HowItWorksStepDescription>
          After the commuter arrives, they will pick up the correct parcel from
          you and proceed to deliver the order to the address specified.
        </HowItWorksStepDescription>
      </HowItWorksStepContentSection>
    </HowItWorksStep>
    <HowItWorksStep reverse extraMarginTop>
      <HowItWorksStepImageSection>
        <HowItWorksStepImage right src={HowItWorksStep5ImageSource} />
      </HowItWorksStepImageSection>
      <HowItWorksStepContentSection>
        <HowItWorksStepNo>5</HowItWorksStepNo>
        <HowItWorksStepTitle>Delivered</HowItWorksStepTitle>
        <HowItWorksStepDescription>
          After arriving at the delivery address, the commuter delivers the
          parcel. They can then continue to their original intended destination.
        </HowItWorksStepDescription>
      </HowItWorksStepContentSection>
    </HowItWorksStep>
  </StyledHowItWorksSteps>
);

export { HowItWorksSteps };