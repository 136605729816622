import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../../../style';

const HowItWorksStepDescription = styled.div`
  line-height: ${STYLES.LINE_HEIGHT_MD};
  color: ${STYLES.COLOR_DOVE_GRAY};
  ${SCREEN_SIZE.LG} {
    width: 20rem;
  }
`;

export { HowItWorksStepDescription };