import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Layout } from '../../components/shared/layout';
import { Page } from '../../components/shared/Page';
import { BusinessesHowToBanner } from '../../components/modules/BusinessesHowToPage';
import { DownloadMerchantsApp } from '../../components/modules/common/DownloadHandCarryApp';
import { HowItWorksSteps } from '../../components/modules/common/HowItWorksSteps';
import { BusinessNavigation } from '../../components/modules/common/BusinessNavigation';
import { LocationProp } from '../../components/shared/props/LocationProp'

const StyledBusinessesHowToPage = styled(Layout)`
  text-align: center;
`;

const BusinessesHowToPage = ({
  location: { pathname },
}) => (
  <StyledBusinessesHowToPage headerTransparent>
    <Page>
      <BusinessesHowToBanner />
      <HowItWorksSteps />
      <BusinessNavigation pathname={pathname} />
      <DownloadMerchantsApp />
    </Page>
  </StyledBusinessesHowToPage>
);

BusinessesHowToPage.propTypes = {
  location: PropTypes.shape(LocationProp).isRequired,
};

export default BusinessesHowToPage;
