import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import BusinessesHowToBannerIconSource from './images/businessHowToBannerIcon.svg';
import { STYLES } from '../../../style';

const StyledBusinessesHowToBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
`;

const BusinessesHowToBanner = () => (
  <StyledBusinessesHowToBanner color={STYLES.PAGE_BUSINESSES_HOW_TO_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={BusinessesHowToBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>HOW DOES IT WORK THOUGH?</BannerTitle>
        <BannerDescription>
          Hand Carry uses the latest technology to make last mile inner city
          deliveries better. Be sure to check out our business pages if you’re an
          eCommerce business.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledBusinessesHowToBanner>
);

export { BusinessesHowToBanner };