import styled from 'styled-components';
import { SCREEN_SIZE } from '../../../../../style'

const HowItWorksStepContentSection = styled.div`
  ${SCREEN_SIZE.LG} {
    margin-right: 7.5rem;
    ${({ extraMarginTop }) => extraMarginTop && 'transform: translateY(2rem);'}
  }
`;

export { HowItWorksStepContentSection };